import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IGroupRead} from '../models/group';
import {TokenService} from './token.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private group$ = new BehaviorSubject<IGroupRead>(null);
  selectedGroup$ = this.group$.asObservable();
  constructor(private tokenService: TokenService,
              private snackBar: MatSnackBar) {}

  setGroup(group: IGroupRead) {
    this.tokenService.saveGroup(group);
    this.group$.next(group);
  }

  showSuccess(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      panelClass: ['snackbar-success'],
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    });
  }

  showError(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 4000,
      panelClass: ['snackbar-error'],
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    });
  }
}
