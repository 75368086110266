import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext, HttpParams } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IGetResponse, IGetResponsePaginated} from '../models/get-response';
import {IPostResponse} from '../models/post-response';
import {IDeleteResponse} from '../models/delete-response';
import {TokenService} from './token.service';
import { IPeopleCreate, IPeopleRead, IPeopleReadWithTags, IPeopleUpdate } from '../models/people';

@Injectable({
  providedIn: 'root',
})
export class PeopleService extends BaseService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetPeopleListPath = '/v1/people';
  static readonly CreatePeoplePostPath = '/v1/people';
  static readonly GetPeopleByIdPath = '/v1/people/{person_id}';
  static readonly UpdatePeoplePath = '/v1/people/{person_id}';
  static readonly RemovePeoplePath = '/v1/people/{person_id}';
  static readonly IncrementCounterPeoplePath = '/v1/people/{person_id}';
  static readonly AttachTagPeoplePath = '/v1/people/{person_id}/tag';
  static readonly RemoveTagPeoplePath = '/v1/people/{person_id}/tag';

  getList(params: {q?: string; page?: number; size?: number; order_by?: string; order?: string}, context?: HttpContext
  ): Observable<IGetResponsePaginated<IPeopleReadWithTags>> {
    const rb = new RequestBuilder(this.rootUrl, PeopleService.GetPeopleListPath, 'get');

    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.query('q', params.q, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      if(params.order_by)
        rb.query('order_by', params.order_by, {});
      if(params.order)
        rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponsePaginated<IPeopleReadWithTags>>;
      }),
      map((r: StrictHttpResponse<IGetResponsePaginated<IPeopleReadWithTags>>) =>
        r.body as IGetResponsePaginated<IPeopleReadWithTags>)
    );
  }

  create(params: {
                body: IPeopleCreate
              },
              context?: HttpContext
  ): Observable<IPostResponse<IPeopleRead>> {

    const rb = new RequestBuilder(this.rootUrl, PeopleService.CreatePeoplePostPath, 'post');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IPeopleRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IPeopleRead>>) =>
        r.body as IPostResponse<IPeopleRead>)
    );
  }

  getById(params: {
            person_id: string;
               },
               context?: HttpContext
  ): Observable<IGetResponse<IPeopleReadWithTags>> {

    const rb = new RequestBuilder(this.rootUrl, PeopleService.GetPeopleByIdPath, 'get');
    if (params) {
      rb.path('person_id', params.person_id, {});
      rb.query('group_id', this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IPeopleReadWithTags>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IPeopleReadWithTags>>) =>
        r.body as IGetResponse<IPeopleReadWithTags>)
    );
  }

  update(params: {
                person_id: string;
                body: IPeopleUpdate
              },
              context?: HttpContext
  ): Observable<IPostResponse<IPeopleReadWithTags>> {

    const rb = new RequestBuilder(this.rootUrl, PeopleService.UpdatePeoplePath, 'put');
    if (params) {
      rb.path('person_id', params.person_id, {});
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IPeopleReadWithTags>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IPeopleReadWithTags>>) =>
        r.body as IPostResponse<IPeopleReadWithTags>),
    );
  }

  remove(params: {
           person_id: string;
              },
              context?: HttpContext
  ): Observable<IDeleteResponse<IPeopleRead>> {

    const rb = new RequestBuilder(this.rootUrl, PeopleService.RemovePeoplePath, 'delete');
    if (params) {
      rb.path('person_id', params.person_id, {});
      rb.query('group_id', this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IDeleteResponse<IPeopleRead>>;
      }),
      map((r: StrictHttpResponse<IDeleteResponse<IPeopleRead>>) =>
        r.body as IDeleteResponse<IPeopleRead>)
    );
  }
}
