import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private readonly layoutService: VexLayoutService) {
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'subheading',
        label: 'Dashboards',
        children: [
          {
            type: 'link',
            label: 'Analytics',
            route: '/',
            icon: 'mat:insights',
            routerLinkActiveOptions: { exact: true },
            adminOnly: false
          },
          {
            type: 'link',
            label: 'Health',
            route: '/health',
            icon: 'mat:health_and_safety',
            routerLinkActiveOptions: { exact: true },
            adminOnly: false
          }
        ],
        adminOnly: false
      },
      {
        type: 'subheading',
        label: 'Trackables',
        children: [
          {
            type: 'link',
            label: 'Things',
            route: '/things',
            icon: 'mat:business_center',
            routerLinkActiveOptions: { exact: true },
            adminOnly: false
          },
          {
            type: 'link',
            label: 'People',
            route: '/people',
            icon: 'mat:group',
            routerLinkActiveOptions: { exact: true },
            adminOnly: false
          },

        ],
        adminOnly: false
      },
      {
        type: 'subheading',
        label: 'Hardware',
        children: [
          {
            type: 'link',
            label: 'Readers',
            route: '/readers',
            icon: 'mat:filter_tilt_shift',
            routerLinkActiveOptions: { exact: true },
            adminOnly: false
          },
          {
            type: 'link',
            label: 'Tags',
            route: '/tags',
            icon: 'mat:label',
            routerLinkActiveOptions: { exact: true },
            adminOnly: false
          }
        ],
        adminOnly: false
      },
      {
        type: 'subheading',
        label: 'Location',
        children: [
          {
            type: 'link',
            label: 'Places',
            route: '/places',
            icon: 'mat:place',
            routerLinkActiveOptions: { exact: true },
            adminOnly: false
          },
        ],
        adminOnly: false
      },
      {
        type: 'subheading',
        label: 'Reports',
        children: [
          {
            type: 'link',
            label: 'Movement',
            route: '/reports/movement',
            icon: 'mat:local_shipping',
            routerLinkActiveOptions: { exact: true },
            adminOnly: false
          },
          {
            type: 'link',
            label: 'Logs',
            route: '/reports/logs',
            icon: 'mat:table_view',
            routerLinkActiveOptions: { exact: true },
            adminOnly: false
          }
        ],
        adminOnly: false
      },
      {
        type: 'subheading',
        label: 'Settings',
        children: [
          {
            type: 'link',
            label: 'Users',
            route: '/users',
            icon: 'mat:person',
            routerLinkActiveOptions: { exact: true },
            adminOnly: false
          },
          {
            type: 'link',
            label: 'Data streams',
            route: '/data-streams',
            icon: 'mat:data_usage',
            routerLinkActiveOptions: { exact: true },
            adminOnly: false
          },
          {
            type: 'link',
            label: 'Groups',
            route: '/groups',
            icon: 'mat:group',
            routerLinkActiveOptions: { exact: true },
            adminOnly: true
          },
          {
            type: 'dropdown',
            label: 'Providers',
            icon: 'mat:all_inbox',
            children: [
              {
                type: 'link',
                label: 'Manufacturers',
                route: '/manufacturers',
                icon: 'mat:build',
                routerLinkActiveOptions: { exact: true },
                adminOnly: true
              },
              {
                type: 'link',
                label: 'Reader Models',
                route: '/reader-models',
                icon: 'mat:more',
                routerLinkActiveOptions: { exact: true },
                adminOnly: true
              },
            ],
            adminOnly: true
          }
        ],
        adminOnly: false
      },
    ]);
  }
}
