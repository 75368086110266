import {Component, Input, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule],
  selector: 'lll-empty-state',
  template: `

    <div class="vex-mail-view-empty flex-auto flex flex-col justify-center items-center p-gutter">
      <div class="empty-state__icon">
        <mat-icon [svgIcon]="icon" class="icon-5xl flex-none"></mat-icon>
      </div>
      <p class="text-lg font-semibold mt-4">{{message}}</p>
    </div>
  `,
  styles: [`
    .empty-state__icon {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      border-radius: 200px;
      justify-content: center;

      img {
        width: 90px;
      }
    }
  `]
})
export class EmptyStateComponent {
  @Input() public message: string = 'No records found';
  @Input() public icon: string = 'mat:business_center';

  constructor() { }

}
