import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {BaseService} from './base-service';
import {TokenService} from './token.service';
import { RequestBuilder } from '../utils/request-builder';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { IPostResponse } from '../models/post-response';
import { IUserRead } from '../models/user';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  constructor(protected http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  login(login: string, password: string): Observable<any> {
    return this.http.post(this.rootUrl + '/v1/login', {
      email: login,
      password: password
    }, httpOptions);
  }

  refreshToken(token: string) {
    return this.http.post(this.rootUrl + '/v1/login/new_access_token', {
      refreshToken: token
    }, httpOptions);
  }

  password_reset_request(email: string): Observable<any> {
    return this.http.post(this.rootUrl + '/v1/login/password-reset-request', {
      email: email
    }, httpOptions);
  }

  password_reset(token: string, email: string, new_password: string, context?: HttpContext): Observable<any> {
    const rb = new RequestBuilder(this.rootUrl, '/v1/login/reset-password', 'put');
    rb.query('password_reset_token', token);
    rb.body({email: email, new_password: new_password}, 'application/json');
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<any>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<any>>) => r.body as IPostResponse<any>)
    );
  }
}
