import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { indoorPlaceResolver, outdoorPlaceListResolver, outdoorPlaceResolver } from './core/resolvers/place.resolver';
import { analyticsDashboardResolver } from './core/resolvers/dashboard.resolver';
import { assetDetailsResolver } from './core/resolvers/asset.resolver';
import { actionResolver } from './core/resolvers/action.resolver';

export const appRoutes: VexRoutes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/auth/login/login.component').then(
        (m) => m.LoginComponent
      )
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import(
        './pages/auth/forgot-password/forgot-password.component'
        ).then((m) => m.ForgotPasswordComponent)
  },
  {
    path: 'password-confirm',
    loadComponent: () =>
      import(
        './pages/auth/password-reset/password-reset.component'
        ).then((m) => m.PasswordResetComponent)
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: '',
        loadComponent: () => import('./pages/dashboards/dynamic-dashboard.component').then(m => m.DynamicDashboardComponent),
      },
      {
        path: 'health',
        loadComponent: () => import('./pages/dashboards/health.component').then(m => m.HealthComponent),
      },
      {
        path: 'things',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/assets/assets-list.component').then(m => m.AssetsListComponent),
          },
          {
            path: ':id',
            loadComponent: () => import('./pages/assets/asset-details/asset-details.component').then(m => m.AssetDetailsComponent),
            resolve: { payload: assetDetailsResolver }
          },
        ],
      },
      {
        path: 'people',
        loadComponent: () => import('./pages/people/people-list.component').then(m => m.PeopleListComponent),
      },
      {
        path: 'data-streams',
        loadComponent: () => import('./pages/data-streams/data-streams-list.component').then(m => m.DataStreamsListComponent),
      },
      {
        path: 'users',
        loadComponent: () => import('./pages/users/users-list.component').then(m => m.UsersListComponent),
      },
      {
        path: 'groups',
        loadComponent: () => import('./pages/groups/groups-list.component').then(m => m.GroupsListComponent),
      },
      {
        path: 'manufacturers',
        loadComponent: () => import('./pages/reader-manufacturer/reader-manufacturer-list.component').then(m => m.ReaderManufacturerListComponent),
      },
      {
        path: 'reader-models',
        loadComponent: () => import('./pages/reader-models/reader-models-list.component').then(m => m.ReaderModelsListComponent),
      },
      {
        path: 'places',
        children: [
          {
            path: 'indoor',
            loadComponent: () => import('./pages/places/indoor/indoor-list.component').then(m => m.IndoorListComponent)
          },
          {
            path: 'outdoor',
            loadComponent: () => import('./pages/places/outdoor/outdoor-list.component').then(m => m.OutdoorListComponent),
            resolve: {payload: outdoorPlaceListResolver}
          },
          {
            path: 'outdoor/:id',
            loadComponent: () => import('./pages/places/outdoor/outdoor.component').then(m => m.OutdoorComponent),
            resolve: {payload: outdoorPlaceResolver}
          },
          {
            path: 'indoor/:id',
            loadComponent: () => import('./pages/places/indoor/indoor.component').then(m => m.IndoorComponent),
            resolve: {payload: indoorPlaceResolver}
          }
        ]
      },
      {
        path: 'readers',
        loadComponent: () => import('./pages/readers/readers-list.component').then(m => m.ReadersListComponent),
      },
      {
        path: 'tags',
        loadComponent: () => import('./pages/tags/tags-list.component').then(m => m.TagsListComponent),
      },
      {
        path: 'actions',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/actions/action-list.component').then(m => m.ActionListComponent),
          },
          {
            path: 'create',
            loadComponent: () => import('./pages/actions/action-edit/action-edit.component').then(m => m.ActionEditComponent),
          },
          {
            path: ':id',
            loadComponent: () => import('./pages/actions/action-edit/action-edit.component').then(m => m.ActionEditComponent),
            resolve: {payload: actionResolver}
          },
        ],
      },
      {
        path: 'reports',
        children: [
          {
            path: 'movement',
            loadComponent: () => import('./pages/reports/movement/movement-report.component').then(m => m.MovementReportComponent)
          },
          {
            path: 'aggregation',
            loadComponent: () => import('./pages/reports/aggregation/aggregation-report.component').then(m => m.AggregationReportComponent)
          },
          {
            path: 'logs',
            loadComponent: () => import('./pages/reports/logs/logs.component').then(m => m.LogsComponent)
          }
        ]
      }
    ]
  }
];
