import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AssetService } from '../../../../core/services/asset.service';
import { TagService } from '../../../../core/services/tag.service';
import { IAssetRead, IAssetReadWithTags } from '../../../../core/models/assets';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ITagReadWithAsset } from '../../../../core/models/tag';
import {EmptyStateComponent} from '../../../../core/utils/empty-state'
import { IPeopleRead } from '../../../../core/models/people';
import { PeopleService } from '../../../../core/services/people.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatRippleModule, ReactiveFormsModule, EmptyStateComponent],
  selector: 'vex-search-modal',
  template: `
    <div>
      <div class="flex items-center gap-4 px-6 py-3 border-b border-divider">
        <mat-icon svgIcon="mat:search" class="text-secondary flex-none"></mat-icon>
        <input type="text" placeholder="Search..." [formControl]="searchCtrl"
               class="text-xl font-medium bg-transparent outline-none flex-auto placeholder-secondary"/>
      </div>

      <div class="p-4">
        <div class="text-lg font-semibold text-secondary px-2 mb-2">Things</div>

        <div class="space-y-1">
          <lll-empty-state *ngIf="!assets || assets?.length === 0"></lll-empty-state>

          <ng-container *ngFor="let asset of assets">
            <div class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
                 matRipple (click)="viewInPlace(asset)" >
              <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
                <mat-icon svgIcon="mat:business_center" class="icon-sm flex-none"></mat-icon>
              </div>
              <div class="flex-auto text-base font-medium">
                <div class="flex-auto text-base font-medium">{{ asset?.label }}</div>
                <div class="text-secondary text-xs" *ngIf="asset.tag_macs">{{asset.tag_macs}}</div>
                <div class="text-secondary text-xs" *ngIf="asset.last_location">last seen at: {{asset.last_location}}</div>
                <div class="text-secondary text-xs" *ngIf="asset.last_seen">last heard of: {{asset.last_seen}}</div>
              </div>
              <div class="flex-none text-xs text-secondary font-medium flex items-center gap-2">
                <div>view in place</div>
                <mat-icon svgIcon="mat:contacts" class="icon-xs flex-none"></mat-icon>
              </div>
              <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="p-4">
        <div class="text-xl font-semibold text-secondary px-2 mb-2">People</div>

        <div class="space-y-1">
          <lll-empty-state *ngIf="!people || people?.length === 0" icon="mat:group"></lll-empty-state>
          <div class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
               matRipple *ngFor="let person of people">
            <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
              <mat-icon svgIcon="mat:group" class="icon-sm flex-none"></mat-icon>
            </div>
            <div class="flex-auto text-base font-medium">
              <div>{{ person?.name }} {{ person?.surname }}</div>
              <div class="text-secondary text-xs">/people/tag_id</div>
            </div>
            <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [``]
})
export class SearchModalComponent implements OnInit {
  searchCtrl = new FormControl();
  isLoading = false;
  errorMsg!: string;
  people: IPeopleRead[];
  assets: IAssetReadWithTags[];

  constructor(private assetService: AssetService,
              private peopleService: PeopleService,
              private router: Router,
              private dialogRef: MatDialogRef<SearchModalComponent>) { }

  ngOnInit(): void {
    this.searchCtrl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.errorMsg = "";
          this.people = [];
          this.isLoading = true;
        }),
        switchMap((value: string) =>
          forkJoin({
            assets: this.assetService.getList({q: value}),
            people: this.peopleService.getList({q: value})
          })
            .pipe(
              finalize(() => {
                this.isLoading = false
              }),
            )
        )
      )
      .subscribe((data: any) => {
        this.people = data?.people?.data?.items;
        const raw_assets: IAssetReadWithTags[] = data?.assets.data?.items;
        for (const asset of raw_assets) {
          asset.tag_macs = asset.linked_tags.map(item => this.formatMacAddress(item.mac_address)).join(", ");
          asset.last_seen = this.populateLastSeen(asset);
          asset.last_location = this.populateLastLocation(asset);
        }
        this.assets = data?.assets.data?.items;
      });
  }

  private populateLastSeen(asset: IAssetReadWithTags) {
    if(!asset || asset?.linked_tags?.length === 0 || !asset.linked_tags[0].last_seen_timestamp) {
      return "";
    }
    return moment.utc(asset.linked_tags[0].last_seen_timestamp).local().fromNow();
  }

  private populateLastLocation(asset: IAssetReadWithTags) {
    if(!asset || asset?.linked_tags?.length === 0 || !asset.linked_tags[0].last_place_seen) {
      return "";
    }
    let place_zone = asset.linked_tags[0].last_place_seen.name;
    if (asset.linked_tags[0].last_zone_seen) {
      place_zone += ` (${asset.linked_tags[0].last_zone_seen.name})`;
    }
    return place_zone;
  }

  formatMacAddress(mac: string): string {
    if (mac.length !== 12) {
      return mac;
    }
    return mac.replace(/(.{2})(?=.)/g, '$1:');
  }

  async viewInPlace(asset: IAssetReadWithTags) {
    if(!asset) {
      return;
    }
    const tag = asset.linked_tags.find(() => true);
    if(!tag || !tag.last_place_seen) {
      return;
    }
    await this.router.navigate(['places', tag.last_place_seen.id, 'indoor']);
    this.dialogRef.close();
  }
}
