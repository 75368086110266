import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IGetResponse} from '../models/get-response';
import {TokenService} from './token.service';
import { ITagsAnalyticsRead, IAverageTimes } from '../models/analytics';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends BaseService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetAverageTimesPath = '/v1/analytics/average-times';
  static readonly GetTagsAnalyticsPath = '/v1/analytics/tags-analytics';

  getAverageTimes(params: {time_range?: string; }, context?: HttpContext
  ): Observable<IGetResponse<IAverageTimes>> {
    const rb = new RequestBuilder(this.rootUrl, AnalyticsService.GetAverageTimesPath, 'get');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.query('time_range', params.time_range, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IAverageTimes>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IAverageTimes>>) => r.body as IGetResponse<IAverageTimes>)
    );
  }

  getTagsAnalytics(context?: HttpContext
  ): Observable<IGetResponse<ITagsAnalyticsRead>> {
    const rb = new RequestBuilder(this.rootUrl, AnalyticsService.GetTagsAnalyticsPath, 'get');
    rb.query('group_id', this.groupId, {});

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<ITagsAnalyticsRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<ITagsAnalyticsRead>>) => r.body as IGetResponse<ITagsAnalyticsRead>)
    );
  }
}
