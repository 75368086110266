import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IGetResponse, IGetResponsePaginated} from '../models/get-response';
import {IPostResponse} from '../models/post-response';
import {IDeleteResponse} from '../models/delete-response';
import {TokenService} from './token.service';
import { IActionCreate, IActionRead, IActionUpdate } from '../models/action';

@Injectable({
  providedIn: 'root',
})
export class ActionService extends BaseService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetActionListPath = '/v1/actions/rule';
  static readonly CreateActionPostPath = '/v1/actions/rule';
  static readonly GetActionByIdPath = '/v1/actions/rule/{action_id}';
  static readonly UpdateActionPath = '/v1/actions/rule/{action_id}';
  static readonly RemoveActionPath = '/v1/actions/rule/{action_id}';

  getList(params: {q?: string; page?: number; size?: number; order_by?: string; order?: string}, context?: HttpContext
  ): Observable<IGetResponsePaginated<IActionRead>> {
    const rb = new RequestBuilder(this.rootUrl, ActionService.GetActionListPath, 'get');
    if (params) {
      rb.query('group_id', this.groupId, {});
      if(params.q)
        rb.query('q', params.q, {});
      if(params.page)
        rb.query('page', params.page, {});
      if(params.size)
        rb.query('size', params.size, {});
      if(params.order_by)
        rb.query('order_by', params.order_by, {});
      if(params.order)
        rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponsePaginated<IActionRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponsePaginated<IActionRead>>) => r.body as IGetResponsePaginated<IActionRead>)
    );
  }

  create(params: {
           body: IActionCreate
         },
         context?: HttpContext
  ): Observable<IPostResponse<IActionRead>> {

    const rb = new RequestBuilder(this.rootUrl, ActionService.CreateActionPostPath, 'post');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IActionRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IActionRead>>) => r.body as IPostResponse<IActionRead>)
    );
  }

  getById(params: {
            action_id: string;
          },
          context?: HttpContext
  ): Observable<IGetResponse<IActionRead>> {

    const rb = new RequestBuilder(this.rootUrl, ActionService.GetActionByIdPath, 'get');
    if (params) {
      rb.path('action_id', params.action_id, {});
      rb.query('group_id', this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IActionRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IActionRead>>) => r.body as IGetResponse<IActionRead>)
    );
  }

  update(params: {
           action_id: string;
           body: IActionUpdate
         },
         context?: HttpContext
  ): Observable<IPostResponse<IActionRead>> {

    const rb = new RequestBuilder(this.rootUrl, ActionService.UpdateActionPath, 'put');
    if (params) {
      rb.path('action_id', params.action_id, {});
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IActionRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IActionRead>>) => r.body as IPostResponse<IActionRead>),
    );
  }

  remove(params: {
           action_id: string;
         },
         context?: HttpContext
  ): Observable<IDeleteResponse<IActionRead>> {

    const rb = new RequestBuilder(this.rootUrl, ActionService.RemoveActionPath, 'delete');
    if (params) {
      rb.path('action_id', params.action_id, {});
      rb.query('group_id', this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IDeleteResponse<IActionRead>>;
      }),
      map((r: StrictHttpResponse<IDeleteResponse<IActionRead>>) => r.body as IDeleteResponse<IActionRead>)
    );
  }
}
