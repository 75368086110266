import { IGetResponse } from '../models/get-response';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';
import { IAverageTimes, ITagsAnalyticsRead } from '../models/analytics';
import { forkJoin } from 'rxjs';



export const analyticsDashboardResolver: ResolveFn<
  {tag_analytics: IGetResponse<ITagsAnalyticsRead>;
    average_times: IGetResponse<IAverageTimes>;}> =
  (route, state) => {
    const analyticsService = inject(AnalyticsService);

    return forkJoin({
      tag_analytics: analyticsService.getTagsAnalytics(),
      average_times: analyticsService.getAverageTimes({ time_range: 'Last 24 hours' })
    });
  };
