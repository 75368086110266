import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IGetResponse, IGetResponsePaginated} from '../models/get-response';
import {IAssetCreate, IAssetRead, IAssetReadWithTags, IAssetUpdate} from '../models/assets';
import {IPostResponse} from '../models/post-response';
import {IDeleteResponse} from '../models/delete-response';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AssetService extends BaseService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetAssetsListPath = '/v1/asset';
  static readonly CreateAssetPostPath = '/v1/asset';
  static readonly GetAssetByIdPath = '/v1/asset/{asset_id}';
  static readonly UpdateAssetPath = '/v1/asset/{asset_id}';
  static readonly RemoveAssetPath = '/v1/asset/{asset_id}';

  getList(params: {q?: string; page?: number; size?: number; order_by?: string; order?: string}, context?: HttpContext
  ): Observable<IGetResponsePaginated<IAssetReadWithTags>> {
    const rb = new RequestBuilder(this.rootUrl, AssetService.GetAssetsListPath, 'get');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.query('q', params.q, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      if(params.order_by)
        rb.query('order_by', params.order_by, {});
      if(params.order)
        rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponsePaginated<IAssetReadWithTags>>;
      }),
      map((r: StrictHttpResponse<IGetResponsePaginated<IAssetReadWithTags>>) => r.body as IGetResponsePaginated<IAssetReadWithTags>)
    );
  }

  create(params: {
                body: IAssetCreate
              },
              context?: HttpContext
  ): Observable<IPostResponse<IAssetRead>> {

    const rb = new RequestBuilder(this.rootUrl, AssetService.CreateAssetPostPath, 'post');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IAssetRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IAssetRead>>) => r.body as IPostResponse<IAssetRead>)
    );
  }

  getById(params: {
                 asset_id: string;
               },
               context?: HttpContext
  ): Observable<IGetResponse<IAssetReadWithTags>> {

    const rb = new RequestBuilder(this.rootUrl, AssetService.GetAssetByIdPath, 'get');
    if (params) {
      rb.path('asset_id', params.asset_id, {});
      rb.query('group_id', this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IAssetReadWithTags>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IAssetReadWithTags>>) => r.body as IGetResponse<IAssetReadWithTags>)
    );
  }

  update(params: {
                asset_id: string;
                body: IAssetUpdate
              },
              context?: HttpContext
  ): Observable<IPostResponse<IAssetReadWithTags>> {

    const rb = new RequestBuilder(this.rootUrl, AssetService.UpdateAssetPath, 'put');
    if (params) {
      rb.path('asset_id', params.asset_id, {});
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IAssetReadWithTags>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IAssetReadWithTags>>) => r.body as IPostResponse<IAssetReadWithTags>),
    );
  }

  remove(params: {
                asset_id: string;
              },
              context?: HttpContext
  ): Observable<IDeleteResponse<IAssetRead>> {

    const rb = new RequestBuilder(this.rootUrl, AssetService.RemoveAssetPath, 'delete');
    if (params) {
      rb.path('asset_id', params.asset_id, {});
      rb.query('group_id', this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IDeleteResponse<IAssetRead>>;
      }),
      map((r: StrictHttpResponse<IDeleteResponse<IAssetRead>>) => r.body as IDeleteResponse<IAssetRead>)
    );
  }
}
