import {ActivatedRouteSnapshot, provideRouter, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {inject} from '@angular/core';
import { IGetResponse } from '../models/get-response';
import { IAssetReadWithTags } from '../models/assets';
import { AssetService } from '../services/asset.service';

export const assetDetailsResolver: ResolveFn<IGetResponse<IAssetReadWithTags>> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(AssetService).getById({asset_id: route.paramMap.get('id')!});
  };

