import {ActivatedRouteSnapshot,  ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import { IGetResponse } from '../models/get-response';
import { IActionRead } from '../models/action';
import { ActionService } from '../services/action.service';

export const actionResolver: ResolveFn<IGetResponse<IActionRead>> =
  (route: ActivatedRouteSnapshot) => {
    return inject(ActionService).getById({action_id: route.paramMap.get('id')!});
  };
